import { BookingsService, BookingWindow } from "./bookings-service";
import BookingWindowTimerAlert from "./components/booking-window-timer-alert";
import PasteOnlyTelephoneInput from "./components/past-only-telephone-input";
import {
  createOption,
  getElementById,
  hideElementById,
  removeAllSelectOptions,
  showElement,
  showElementById,
} from "./util";

const OUTSIDE_BOOKING_WINDOW_ALERT_ID = "outside_bookings_window_alert";
const BOOKING_TIMER_ALERT_ID = "booking_timer_alert";
const POSTCODE_INPUT_ID = "postcode";
const SUBURB_INPUT_ID = "suburb";
const TELEPHONE_INPUT_ID = "telephone";
const FIRST_NAME_INPUT_ID = "first_name";
const LAST_NAME_INPUT_ID = "last_name";
const TELEPHONE_CLEAR_BUTTON_ID = "telephone_clear";
const SAVE_BUTTON_ID = "save_registration_button";
const OUTSIDE_BOOKINGS_WINDOW_ALERT_ID = "outside_bookings_window_alert";
const POSTCODE_ERROR_ALERT_ID = "postcode_error_alert";
const ELIGIBILITY_ALERT_ID = "eligibility_alert";
const SUBURBS_LOADING_INFO_ID = "suburbs_loading_info";

export default function buildRegistrationsBookingPage(
  bookingWindowApiUrl: string,
  eligibilityApiUrl: string,
  suburbsApiUrl: string,
  bookingsApiUrl: string
) {
  const bookingsService = new BookingsService(
    document.querySelector("meta[name=csrf-token]")?.getAttribute("content") ?? "",
    bookingWindowApiUrl,
    eligibilityApiUrl,
    suburbsApiUrl,
    bookingsApiUrl
  );

  return new RegistrationsPage(bookingsService);
}

class RegistrationsPage {
  private readonly _bookingsService: BookingsService;

  private _bookingWindowTimerAlert: BookingWindowTimerAlert | undefined;
  private _bookingWindow: BookingWindow | undefined = undefined;

  constructor(bookingsService: BookingsService) {
    this._bookingsService = bookingsService;
  }

  public init = async () => {
    const postcodeInput = getElementById<HTMLInputElement>(POSTCODE_INPUT_ID);
    const suburbInput = getElementById<HTMLSelectElement>(SUBURB_INPUT_ID);
    const telephoneInput = getElementById<HTMLInputElement>(TELEPHONE_INPUT_ID);
    const telephoneInputClearButton = getElementById<HTMLButtonElement>(TELEPHONE_CLEAR_BUTTON_ID);
    const outsideBookingWindowAlert = getElementById<HTMLElement>(OUTSIDE_BOOKING_WINDOW_ALERT_ID);
    const bookingTimerAlert = getElementById<HTMLElement>(BOOKING_TIMER_ALERT_ID);

    this._bookingWindow = await this.getBookingWindow();
    if (!this._bookingWindow.isInsideBookingWindow) {
      this.setInputsDisabledState(true, { disableAll: true });
      showElementById(OUTSIDE_BOOKINGS_WINDOW_ALERT_ID);
      return;
    }

    this._bookingWindowTimerAlert = new BookingWindowTimerAlert(
      outsideBookingWindowAlert,
      bookingTimerAlert,
      this.setInputsDisabledState
    );
    this._bookingWindowTimerAlert.setBookingWindow(this._bookingWindow);

    const pasteOnlyTelephoneInput = new PasteOnlyTelephoneInput(telephoneInput, telephoneInputClearButton);
    pasteOnlyTelephoneInput.init();

    postcodeInput.addEventListener("input", this.handlePostcodeInput);
    suburbInput.addEventListener("change", this.handleSuburbInputChanged);
  };

  private handlePostcodeInput = async (e: Event) => {
    e.preventDefault();

    const currentTarget = e.currentTarget as HTMLInputElement;

    if (currentTarget.value.length >= 4) {
      await this.loadSuburbsForPostcode(currentTarget.value);
      return;
    } else {
      hideElementById(POSTCODE_ERROR_ALERT_ID);
      hideElementById(ELIGIBILITY_ALERT_ID);

      removeAllSelectOptions(getElementById<HTMLSelectElement>(SUBURB_INPUT_ID));
    }

    if (isNaN(parseInt(currentTarget.value.slice(-1)))) {
      currentTarget.value = `${currentTarget.value}`.substring(0, currentTarget.value.length - 1);
      return;
    }
  };

  private handleSuburbInputChanged = async (e: Event) => {
    const currentTarget = e.currentTarget as HTMLInputElement;
    if (!currentTarget.value) return;

    const postcode = getElementById<HTMLInputElement>(POSTCODE_INPUT_ID).value;
    const suburb = currentTarget.value;
    await this.checkEligibility(postcode, suburb);
  };

  private getBookingWindow = async (): Promise<BookingWindow> => {
    try {
      return await this._bookingsService.getBookingWindow();
    } catch (err) {
      alert("An error occurred attempting to load registrations, please refresh the page and try again");

      return {
        isInsideBookingWindow: false,
        timeLeftSeconds: 0,
        leniencyPeriodMinutes: 0,
      };
    }
  };

  private loadSuburbsForPostcode = async (postcode: string) => {
    hideElementById(POSTCODE_ERROR_ALERT_ID);
    hideElementById(ELIGIBILITY_ALERT_ID);

    showElementById(SUBURBS_LOADING_INFO_ID);

    try {
      const result = await this._bookingsService.getSuburbsForPostcode(postcode);

      if (result.error) {
        this.setInputsDisabledState(true);
        this.showPostcodeError(result.error);
      } else {
        this.setInputsDisabledState(false);
      }

      return this.setSuburbSelectOptions(result.suburbs);
    } catch (err) {
      alert("An error occurred attempting to retrieve suburbs for the supplied postcode, please try again");
    } finally {
      hideElementById(SUBURBS_LOADING_INFO_ID);
    }
  };

  private checkEligibility = async (postcode: string, suburb: string) => {
    try {
      const eligibility = await this._bookingsService.getEligibility(postcode, suburb);
      if (!eligibility.eligible) {
        this.handleUneligiblePostcode(eligibility.reason);
      }
    } catch (err) {
      alert("An error occurred attempting to validate eligibility for the supplied postcode, please try again");
    }
  };

  private setSuburbSelectOptions = (suburbs: string[]) => {
    const suburbInput = getElementById<HTMLSelectElement>(SUBURB_INPUT_ID);

    removeAllSelectOptions(suburbInput);
    if (!suburbs || !suburbs.length) return;

    for (let suburb of suburbs) {
      suburbInput.add(createOption(suburb, suburb));
    }

    suburbInput.dispatchEvent(new Event("change"));
  };

  private setInputsDisabledState = (disabled: boolean, options: { disableAll: boolean } = { disableAll: false }) => {
    getElementById<HTMLInputElement>(FIRST_NAME_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(LAST_NAME_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(TELEPHONE_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(SAVE_BUTTON_ID).disabled = disabled;

    if (options.disableAll) {
      getElementById<HTMLInputElement>(POSTCODE_INPUT_ID).disabled = disabled;
      getElementById<HTMLInputElement>(SUBURB_INPUT_ID).disabled = disabled;
    }
  };

  private showPostcodeError = (error: string) => {
    const postcodeAlert = getElementById<HTMLElement>(POSTCODE_ERROR_ALERT_ID);
    postcodeAlert.innerHTML = `<p>${error}</p>`;

    showElement(postcodeAlert);
  };

  private handleUneligiblePostcode = (reason: string) => {
    let uneligibleReason = "";
    let canCompleteRegistration = false;

    switch (reason) {
      case "postcode":
        uneligibleReason = "The specified postcode could not be found, please confirm it's correct";
        break;
      case "state":
        uneligibleReason =
          "The state associated with the specified postcode is outside of VIC and does not currently qualify for a free Powerpal installation";
        break;
      case "region":
        canCompleteRegistration = true;
        uneligibleReason =
          "The supplied postcode falls within a region that is not currently serviced by Powerpal due to its location. The customer can still be registered but will go on to our wait list until we begin servicing their region";
        break;
      default:
        uneligibleReason = "The supplied postcode is not eligible to receive a Powerpal under the VEU scheme";
        break;
    }

    const postcodeAlert = getElementById<HTMLElement>(ELIGIBILITY_ALERT_ID);
    postcodeAlert.innerHTML = `<p>${uneligibleReason}</p>`;
    postcodeAlert.classList.remove("is-danger");
    postcodeAlert.classList.remove("is-info");
    postcodeAlert.classList.add(canCompleteRegistration ? "is-info" : "is-danger");

    showElement(postcodeAlert);

    this.setInputsDisabledState(!canCompleteRegistration);
  };
}
