const init = () => {
  const burger = document.querySelector(".navbar-burger");
  if (burger == null) return;

  burger.addEventListener("click", function () {
    burger.classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  });
};

export default init;
