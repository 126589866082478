import DevicesService, {
  Distributor,
  Retailer,
  TariffData,
  TariffTimeOfUseParams,
  TariffType,
  TimeOfUseRate,
  TimeOfUseSeason,
  TimeWindow,
} from "./devices_service";
import { show, hide } from "./util";

interface InstallationsTariff {
  retailer_code: string;
  distributor_code: string;
  variant: string;
  peak_cost_per_kwh: number;
  shoulder_cost_per_kwh: number | null;
  off_peak_cost_per_kwh: number | null;
}

const init = (
  formSelector: string,
  postcode: string,
  fieldPrefix: string,
  existingTariff: InstallationsTariff,
  devicesServiceUrl: string
) => {
  const devicesService = new DevicesService(devicesServiceUrl);

  const form = document.querySelector(formSelector) as HTMLFormElement;
  const spinner = form.querySelector(".loading") as HTMLElement;
  const retailerSelect = form.querySelector(`[name='${fieldPrefix}[retailer_code]']`) as HTMLSelectElement;
  const distributorSelect = form.querySelector(`[name='${fieldPrefix}[distributor_code]']`) as HTMLSelectElement;
  const rateCountSelect = form.querySelector("#rate-count-select") as HTMLSelectElement;
  const typeField = form.querySelector(`[name='${fieldPrefix}[type]']`) as HTMLSelectElement;
  const variantSelect = form.querySelector(`[name='${fieldPrefix}[variant]']`) as HTMLSelectElement;
  const planSummaryContainer = form.querySelector("#plan-summary-container") as HTMLElement;

  let retailers: Retailer[] = [];
  let distributors: Distributor[] = [];
  let plans: TariffData[] = [];
  lookupDistributorsAndRetailers();

  distributorSelect.addEventListener("change", () => {
    providerChanged();
  });

  retailerSelect.addEventListener("change", () => {
    providerChanged();
  });

  rateCountSelect.addEventListener("change", () => {
    changeRateCount(parseInt(rateCountSelect.value));
  });

  variantSelect.addEventListener("change", () => {
    showTimeOfUseSummary(variantSelect.value);
  });

  async function lookupDistributorsAndRetailers() {
    try {
      show(spinner);
      const providers = await devicesService.lookupTariffProviders(postcode);
      retailers = providers.retailers;
      distributors = providers.distributors;

      showRetailers(retailers);

      if (distributors.length === 1) {
        showSingleDistributor(distributors[0]);
      } else {
        showMultipleDistributors(distributors);
      }

      providerChanged();
    } catch (err) {
      alert(`Error looking up distributors and retailers for ${postcode}:\n\n${err}`);
    }
    hide(spinner);
  }

  async function providerChanged() {
    // Not just an optimisation – the distributorSelect doesn't exist to pull a value from if there's only one choice.
    const distributorCode = distributors.length > 1 ? distributorSelect.value : distributors[0].code;

    const retailerCode = retailerSelect.value;
    const fieldset = form.querySelector("fieldset.depends-on-provider") as HTMLElement;
    const amberNotes = form.querySelector(".amber-notes") as HTMLElement;

    if (retailerCode === "AMBER") {
      show(amberNotes);
    } else {
      hide(amberNotes);
    }

    if (distributorCode === "" || retailerCode === "") {
      hide(fieldset);
      return;
    }

    try {
      show(spinner);
      const lookup = await devicesService.lookupTariffPlans(distributorCode, retailerCode);
      plans = lookup.plans;
      showPlans();
    } catch (err) {
      alert(`Error looking up plans for ${distributorCode}/${retailerCode}:\n\n${err}`);
    }

    hide(spinner);
    show(fieldset);
  }

  function showPlans() {
    const labels: { [n: number]: string } = {
      1: "Single rate",
      2: "Peak / off-peak",
      3: "Peak / shoulder / off-peak",
    };

    rateCountSelect.innerHTML = "";

    const rateCounts = new Set<number>();
    plans.forEach((p) => rateCounts.add(countRates(p)));

    rateCounts.forEach((c) => {
      rateCountSelect.innerHTML += `<option value="${c}">${labels[c]}</option>`;
    });

    if (existingTariff.shoulder_cost_per_kwh !== null) {
      rateCountSelect.value = "3";
      changeRateCount(3);
      return;
    }

    if (existingTariff.off_peak_cost_per_kwh !== null) {
      rateCountSelect.value = "2";
      changeRateCount(2);
      return;
    }

    setSingleRate();
  }

  function changeRateCount(count: number) {
    switch (count) {
      case 1:
        showRateField("peak_cost_per_kwh");
        hideRateField("shoulder_cost_per_kwh");
        hideRateField("off_peak_cost_per_kwh");
        setSingleRate();
        break;

      case 2:
        showRateField("peak_cost_per_kwh");
        hideRateField("shoulder_cost_per_kwh");
        showRateField("off_peak_cost_per_kwh");
        setTimeOfUse(2);
        break;

      case 3:
        showRateField("peak_cost_per_kwh");
        showRateField("shoulder_cost_per_kwh");
        showRateField("off_peak_cost_per_kwh");
        setTimeOfUse(3);
        break;
    }
  }

  function hideRateField(name: "peak_cost_per_kwh" | "shoulder_cost_per_kwh" | "off_peak_cost_per_kwh") {
    const input = form.querySelector(`[name='${fieldPrefix}[${name}]']`) as HTMLInputElement;
    hide(input.closest(".field") as HTMLElement);
    input.value = "";
    input.required = false;
  }

  function showRateField(name: "peak_cost_per_kwh" | "shoulder_cost_per_kwh" | "off_peak_cost_per_kwh") {
    const input = form.querySelector(`[name='${fieldPrefix}[${name}]']`) as HTMLInputElement;
    show(input.closest(".field") as HTMLElement);
    input.value = existingTariff[name]?.toString() || "";
    input.required = true;
  }

  function showSingleDistributor(distributor: Distributor) {
    // Replace the select with a hidden field; Bulma doesn't style disabled selects nicely.
    const field = distributorSelect.closest(".field") as HTMLElement;
    field.innerHTML += `
      <input type="hidden" name="${distributorSelect.name}" value="${distributor.code}"/>
      <input type="text" value="${distributor.name}", class="input is-static"/>
    `;
    field.querySelector(".control")?.remove(); // Bulma nests selects inside styled .control divs.
  }

  function showMultipleDistributors(distributors: Distributor[]) {
    distributors.forEach((d) => {
      distributorSelect.innerHTML += `<option value="${d.code}">${d.name}</option>`;
    });
    distributorSelect.value = existingTariff.distributor_code;
  }

  function showRetailers(retailers: Retailer[]) {
    retailers.forEach((r) => {
      retailerSelect.innerHTML += `<option value="${r.code}">${r.name}</option>`;
    });
    retailerSelect.value = existingTariff.retailer_code;
  }

  function setSingleRate() {
    typeField.value = "single-rate";
    variantSelect.innerHTML = `<option value="single-rate">Single rate</option>`;
    hide(variantSelect.closest(".field") as HTMLElement, planSummaryContainer);
  }

  function setTimeOfUse(rateCount: number) {
    typeField.value = "time-of-use";
    variantSelect.innerHTML = "";

    const plansWithRateCount = plans.filter((p) => countRates(p) === rateCount);
    plansWithRateCount.forEach((p) => {
      variantSelect.innerHTML += `<option value="${p.variant}">${p.name}</option>`;
    });

    const existingPlan = plansWithRateCount.find((p) => p.variant === existingTariff.variant);
    variantSelect.value = existingPlan?.variant || plansWithRateCount[0].variant;

    show(variantSelect.closest(".field") as HTMLElement, planSummaryContainer);
    showTimeOfUseSummary(variantSelect.value);
  }

  function showTimeOfUseSummary(variant: string) {
    const plan = plans.find((p) => p.variant === variant) as TariffData;
    const params = plan.params as TariffTimeOfUseParams;

    function timesText(times: TimeWindow[]) {
      return times.map((t) => `${t.start} to ${t.end}`).join(", ");
    }

    planSummaryContainer.innerHTML = params.seasons[0].rates
      .map((p) => {
        return `<p>
        <em>${p.name}</em>
        ${p.weekday.length > 0 ? `weekdays ${timesText(p.weekday)}` : ""}
        ${p.weekday.length > 0 && p.weekend.length > 0 ? " / " : ""}
        ${p.weekend.length > 0 ? `weekends ${timesText(p.weekend)}` : ""}
        ${p.weekend.length + p.weekday.length == 0 ? "all other times" : ""}
      </p>`;
      })
      .join("");
  }
};

function countRates(plan: TariffData): number {
  if (plan.type === TariffType.singleRate) return 1;
  return getTimeOfUseRates(plan).length;
}

function getTimeOfUseRates(plan: TariffData): TimeOfUseRate[] {
  const params = plan.params as TariffTimeOfUseParams;
  return seasonWithMostRates(params).rates;
}

function seasonWithMostRates(params: TariffTimeOfUseParams): TimeOfUseSeason {
  return params.seasons.sort((a, b) => b.rates.length - a.rates.length)[0];
}

export default init;
