import tablesort from "tablesort";

import initBulma from "./bulma";
import initAddressAutocomplete from "./address_autocomplete";
import initTariffForm from "./tariff_form";
import installationSchedule from "./installation_schedule";
import imageCapture from "./image_capture";
import appointmentsPage from "./bookings/appointments";
import registrationsPage from "./bookings/registrations";
import buildInstallationScheduleRequestTable from "./installation_schedules/requests_table";
import buildFacebookAdsTable from "./facebook_ads/facebook_ads_table";

// Simple helpers to run on every page.
document.addEventListener("DOMContentLoaded", function () {
  initBulma();
  initAddressAutocomplete();
});

// Expose more specialised components to be called from <script> tags as necessary.
window.powerpal = window.powerpal || {};
window.powerpal.tablesort = tablesort;
window.powerpal.initTariffForm = initTariffForm;
window.powerpal.installationSchedule = installationSchedule;
window.powerpal.imageCapture = imageCapture;
window.powerpal.bookings = { appointmentsPage, registrationsPage };
window.powerpal.buildInstallationScheduleRequestTable = buildInstallationScheduleRequestTable;
window.powerpal.buildFacebookAdsTable = buildFacebookAdsTable;

// TODO: RG, 2021-06-03: Util Should not be leaked, but we currently have a bunch of inline <scripts> that use it. These should be modules.
import * as util from "./util";
window.powerpal.util = util;
