import { BookingWindow } from "../bookings-service";
import { hideElement, showElement } from "../util";

export default class BookingWindowTimerAlert {
  private readonly _outsideWindowAlert: HTMLElement;
  private readonly _countdownTimerAlert: HTMLElement;
  private readonly _disableInputsFunc: (disable: boolean) => void;

  private _bookingWindow: BookingWindow | undefined;
  private _bookingTimeWindowInterval: NodeJS.Timeout | undefined;
  private _remainingTimeInSeconds: number = 0;

  constructor(
    outsideWindowAlert: HTMLElement,
    countdownTimerAlert: HTMLElement,
    disableInputsFunc: (disable: boolean) => void
  ) {
    this._outsideWindowAlert = outsideWindowAlert;
    this._countdownTimerAlert = countdownTimerAlert;
    this._disableInputsFunc = disableInputsFunc;
  }

  public setBookingWindow = (bookingWindow: BookingWindow) => {
    this._bookingWindow = bookingWindow;
    this._remainingTimeInSeconds = bookingWindow.timeLeftSeconds;

    if (this._bookingTimeWindowInterval) {
      clearInterval(this._bookingTimeWindowInterval);
    }

    if (this._bookingWindow.timeLeftSeconds <= 0) {
      this.displayOutsideBookingWindowAlert();
      return;
    }

    this.displayBookingWindowTimer();

    this._bookingTimeWindowInterval = setInterval(this.handleTimerTick, 1000);
  };

  private displayBookingWindowTimer = () => {
    if (!this._bookingWindow) return;

    // Only display bookings window timer if we're within the leniency period from the window closing to help
    // the agent know they might not be able to complete what they're doing
    if (this._remainingTimeInSeconds <= this._bookingWindow.leniencyPeriodMinutes * 60) {
      const minute = Math.floor(this._remainingTimeInSeconds / 60);
      const remainingMinutesText = minute > 0 ? `${minute} minutes and ` : "";
      const remainingSecondsText = `${this._remainingTimeInSeconds - minute * 60} seconds`;

      this._countdownTimerAlert.innerHTML = `<p><strong>Bookings will no longer be able to be created in ${remainingMinutesText}${remainingSecondsText}</strong></p>`;

      showElement(this._countdownTimerAlert);
    }
  };

  private displayOutsideBookingWindowAlert() {
    hideElement(this._countdownTimerAlert);
    showElement(this._outsideWindowAlert);
    this._disableInputsFunc(true);
  }

  private handleTimerTick = () => {
    if (this._bookingTimeWindowInterval && this._remainingTimeInSeconds <= 0) {
      this.displayOutsideBookingWindowAlert();
      return clearInterval(this._bookingTimeWindowInterval);
    }

    this.displayBookingWindowTimer();

    this._remainingTimeInSeconds--;
  };
}
