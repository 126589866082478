import moment from "moment";

import { AvailableSchedules, BookingsService, Eligibility, Schedule } from "./bookings-service";
import BookingWindowTimerAlert from "./components/booking-window-timer-alert";
import PasteOnlyTelephoneInput from "./components/past-only-telephone-input";
import {
  createOption,
  getElementById,
  hideElementById,
  removeAllSelectOptions,
  showElement,
  showElementById,
} from "./util";

const OUTSIDE_BOOKING_WINDOW_ALERT_ID = "outside_bookings_window_alert";
const BOOKING_TIMER_ALERT_ID = "booking_timer_alert";
const BOOKINGS_LOADING_INFO_ID = "bookings-loading-info";
const NO_SCHEDULES_AVAILABLE_ALERT_ID = "no_schedules_available_alert";
const ADDRESS_ELIGIBILITY_ALERT_ID = "address_eligibility_alert";
const ADDRESS_INPUT_ID = "address";
const ADDRESS_GNAF_INPUT_ID = "address_gnaf_id";
const ADDRESS_POSTCODE_INPUT_ID = "address_postcode";
const ADDRESS_SUBURB_INPUT_ID = "address_suburb";
const INSTALLATION_SCHEDULE_GROUP_ID = "installation_schedule_group";
const INSTALLATION_SCHEDULE_SELECT_INPUT_ID = "installation_schedule";
const TIME_OF_DAY_GROUP_ID = "time_of_day_group";
const TIME_OF_DAY_SELECT_INPUT_ID = "time_of_day";
const TELEPHONE_INPUT_ID = "telephone";
const FIRST_NAME_INPUT_ID = "first_name";
const LAST_NAME_INPUT_ID = "last_name";
const NOTES_INPUT_ID = "notes";
const NOTES_GROUP_ID = "notes_group";
const SAVE_BUTTON_ID = "save_booking_button";
const TELEPHONE_CLEAR_BUTTON_ID = "telephone_clear";

const EMPTY_BOOKINGS_AVAILABILITY = {
  isInsideBookingWindow: false,
  timeLeftSeconds: 0,
  leniencyPeriodMinutes: 0,
  schedules: [],
};

export default function buildAppointmentsPage(
  bookingWindowApiUrl: string,
  eligibilityApiUrl: string,
  suburbsApiUrl: string,
  bookingsApiUrl: string
) {
  const bookingsService = new BookingsService(
    document.querySelector("meta[name=csrf-token]")?.getAttribute("content") ?? "",
    bookingWindowApiUrl,
    eligibilityApiUrl,
    suburbsApiUrl,
    bookingsApiUrl
  );

  return new AppointmentsPage(bookingsService);
}

enum Timeslot {
  morning = "morning",
  midday = "midday",
  afternoon = "afternoon",
  lateAfternoon = "late-afternoon",
  any = "any",
}

enum RouteType {
  singlepass = "singlepass",
  doublepass = "doublepass",
  multipass = "multipass",
  quadpass = "quadpass",
}

class AppointmentsPage {
  private readonly _bookingsService: BookingsService;

  private _bookingWindowTimerAlert: BookingWindowTimerAlert | undefined;
  private _bookingsAvailability: AvailableSchedules = EMPTY_BOOKINGS_AVAILABILITY;

  constructor(bookingsService: BookingsService) {
    this._bookingsService = bookingsService;
  }

  public init = async (postcode: string) => {
    const addressGnafInput = getElementById<HTMLInputElement>(ADDRESS_GNAF_INPUT_ID);
    const installationScheduleSelect = getElementById<HTMLSelectElement>(INSTALLATION_SCHEDULE_SELECT_INPUT_ID);
    const telephoneInput = getElementById<HTMLInputElement>(TELEPHONE_INPUT_ID);
    const telephoneInputClearButton = getElementById<HTMLButtonElement>(TELEPHONE_CLEAR_BUTTON_ID);
    const outsideBookingWindowAlert = getElementById<HTMLElement>(OUTSIDE_BOOKING_WINDOW_ALERT_ID);
    const bookingTimerAlert = getElementById<HTMLElement>(BOOKING_TIMER_ALERT_ID);

    this.setIsLoading(true);
    this._bookingsAvailability = await this.getAvailableSchedules(postcode);
    this.setIsLoading(false);

    this._bookingWindowTimerAlert = new BookingWindowTimerAlert(
      outsideBookingWindowAlert,
      bookingTimerAlert,
      this.setInputsDisabledState
    );
    this._bookingWindowTimerAlert.setBookingWindow(this._bookingsAvailability);

    const pasteOnlyTelephoneInput = new PasteOnlyTelephoneInput(telephoneInput, telephoneInputClearButton);
    pasteOnlyTelephoneInput.init();

    addressGnafInput.addEventListener("change", this.handleAddressGnafChanged);
    installationScheduleSelect.addEventListener("change", this.handleInstallationScheduleChanged);

    if (postcode) {
      this.setScheduleSelectOptions(this._bookingsAvailability.schedules);
    }
  };

  private setIsLoading = (loading: boolean) => {
    this.setInputsDisabledState(loading);

    if (loading) {
      hideElementById(NO_SCHEDULES_AVAILABLE_ALERT_ID);
      showElementById(BOOKINGS_LOADING_INFO_ID);
    } else {
      hideElementById(BOOKINGS_LOADING_INFO_ID);
    }
  };

  private getAvailableSchedules = async (postcode: string): Promise<AvailableSchedules> => {
    try {
      const bookingsAvailability = await this._bookingsService.getAvailableSchedules(postcode);
      if (!bookingsAvailability.isInsideBookingWindow) {
        return EMPTY_BOOKINGS_AVAILABILITY;
      }

      return bookingsAvailability;
    } catch (err) {
      alert("We were unable to retrieve the available appointments for an unexpected reason. Please try again.");
      return EMPTY_BOOKINGS_AVAILABILITY;
    }
  };

  private getAddressEligibility = async (
    postcode: string,
    suburb: string,
    gnaf: string
  ): Promise<Eligibility | undefined> => {
    try {
      return this._bookingsService.getEligibility(postcode, suburb, gnaf);
    } catch (err) {
      alert(
        "An unexpected issue occurred while attempting to validate the eligibility of the supplied address. Please try again."
      );
      return undefined;
    }
  };

  private hideAllAlerts = () => {
    hideElementById(ADDRESS_ELIGIBILITY_ALERT_ID);
    hideElementById(NO_SCHEDULES_AVAILABLE_ALERT_ID);
  };

  private setInputsDisabledState = (
    disabled: boolean,
    options: { disableAddress: boolean } = { disableAddress: true }
  ) => {
    getElementById<HTMLSelectElement>(INSTALLATION_SCHEDULE_SELECT_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(TIME_OF_DAY_SELECT_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(ADDRESS_INPUT_ID).disabled = disabled && options.disableAddress;
    getElementById<HTMLInputElement>(FIRST_NAME_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(LAST_NAME_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(TELEPHONE_INPUT_ID).disabled = disabled;
    getElementById<HTMLInputElement>(NOTES_INPUT_ID).disabled = disabled;
    getElementById<HTMLButtonElement>(SAVE_BUTTON_ID).disabled = disabled;
  };

  private setScheduleSelectOptions = (schedules: Schedule[]) => {
    if (schedules.length === 0) {
      this.showCanOnlyRegisterAlert("There are no appointments available for that address at this time.");
      this.hideScheduleSelectInputs();
    } else {
      this.hideAllAlerts();
      this.showScheduleSelectInputs();
    }

    this.setBookingDateSelectOptions(schedules);
  };

  private setPreferredTimeslots = (availableSchedules: Schedule[]) => {
    const installationScheduleSelect = getElementById<HTMLSelectElement>(INSTALLATION_SCHEDULE_SELECT_INPUT_ID);
    const preferredTimeslotSelect = getElementById<HTMLSelectElement>(TIME_OF_DAY_SELECT_INPUT_ID);

    removeAllSelectOptions(preferredTimeslotSelect);

    const scheduleId = parseInt(installationScheduleSelect.value);
    const schedule = availableSchedules.find((s) => s.scheduleId === scheduleId);
    if (!schedule) return;

    let routeType = schedule.routeType;
    if (!routeType) routeType = RouteType.multipass;

    const optionDescriptions = this.getTimeslotDescriptions(routeType as RouteType);
    const anyOption = createOption(Timeslot.any, optionDescriptions.anyOptionDescription);
    const morningOption = createOption(Timeslot.morning, optionDescriptions.morningOptionDescription);
    const middayOption = createOption(Timeslot.midday, optionDescriptions.middayOptionDescription);
    const afternoonOption = createOption(Timeslot.afternoon, optionDescriptions.afternoonOptionDescription);
    const lateAfternoonOption = createOption(Timeslot.lateAfternoon, optionDescriptions.lateAfternoonOptionDescription);

    preferredTimeslotSelect.add(anyOption, null);

    if (routeType !== RouteType.singlepass) preferredTimeslotSelect.add(morningOption, null);
    if (routeType === RouteType.multipass || routeType === RouteType.quadpass)
      preferredTimeslotSelect.add(middayOption, null);
    if (routeType !== RouteType.singlepass) preferredTimeslotSelect.add(afternoonOption, null);
    if (routeType === RouteType.quadpass) preferredTimeslotSelect.add(lateAfternoonOption, null);
  };

  private getTimeslotDescriptions(routeType: RouteType) {
    let anyOptionDescription = "Any time (8am to 6pm)";
    let morningOptionDescription = "Morning (8am to 11am)";
    let middayOptionDescription = "Midday (11am to 3pm)";
    let afternoonOptionDescription = "Afternoon (3pm to 6pm)";
    let lateAfternoonOptionDescription = "Late Afternoon (3pm to 6pm)";

    if (routeType === RouteType.quadpass) {
      anyOptionDescription = "Any time (8am to 6pm)";
      morningOptionDescription = "8am to 11am";
      middayOptionDescription = "11am to 1pm";
      afternoonOptionDescription = "1pm to 3pm";
      lateAfternoonOptionDescription = "3pm to 6pm";
    }

    return {
      anyOptionDescription,
      morningOptionDescription,
      middayOptionDescription,
      afternoonOptionDescription,
      lateAfternoonOptionDescription,
    };
  }

  private setBookingDateSelectOptions = (schedules: Schedule[]) => {
    const installationScheduleSelect = getElementById<HTMLSelectElement>(INSTALLATION_SCHEDULE_SELECT_INPUT_ID);
    const preferredTimeslotSelect = getElementById<HTMLSelectElement>(TIME_OF_DAY_SELECT_INPUT_ID);

    removeAllSelectOptions(installationScheduleSelect);
    removeAllSelectOptions(preferredTimeslotSelect);

    const noDateOption = createOption("-1", "No date selected");
    noDateOption.setAttribute("selected", "selected");
    installationScheduleSelect.add(noDateOption);

    for (let schedule of schedules) {
      installationScheduleSelect.add(
        createOption(
          schedule.scheduleId.toString(),
          `${moment(schedule.scheduleDate).format("dddd, D MMMM YYYY")} (${schedule.availableSpots} spots remaining)`
        )
      );
    }
  };

  private showCanOnlyRegisterAlert = (title: string) => {
    const noSchedulesAlert = getElementById<HTMLElement>(NO_SCHEDULES_AVAILABLE_ALERT_ID);

    noSchedulesAlert.innerHTML = `
    <p><strong>${title}</strong></p>
    <p>You can still register the customer details and they will be contacted when a date becomes available in their area.</p>
  `;

    showElement(noSchedulesAlert);
  };

  private hideScheduleSelectInputs = () => {
    hideElementById(INSTALLATION_SCHEDULE_GROUP_ID);
    hideElementById(TIME_OF_DAY_GROUP_ID);
    hideElementById(NOTES_GROUP_ID);

    removeAllSelectOptions(getElementById<HTMLSelectElement>(INSTALLATION_SCHEDULE_SELECT_INPUT_ID));
    removeAllSelectOptions(getElementById<HTMLSelectElement>(TIME_OF_DAY_SELECT_INPUT_ID));
    getElementById<HTMLInputElement>(NOTES_INPUT_ID).value = "";
  };

  private showScheduleSelectInputs = () => {
    showElementById(INSTALLATION_SCHEDULE_GROUP_ID);
    showElementById(TIME_OF_DAY_GROUP_ID);
  };

  private handleUneligibleAddress = (reason: string) => {
    let uneligibleReason = "";
    let canStillRegister = false;

    switch (reason) {
      case "state":
        uneligibleReason =
          "The home at the specified address is outside of VIC and does not currently qualify for a free Powerpal installation";
        break;
      case "gnaf":
        uneligibleReason =
          "The home at the specified address has already had a free Powerpal installation. Under the terms of the Victorian Energy Upgrades scheme, upgrades are per-address rather than per-person. The customer can contact veu@esc.vic.gov.au if they believe this is an error";
        break;
      case "region":
        canStillRegister = true;
        uneligibleReason =
          "The home at the specified address falls within a region that is not currently serviced by Powerpal due to its location.";
        break;
      case "appointment_already_booked":
        uneligibleReason = "The home at the specified address already has an appointment booked.";
        break;
      default:
        uneligibleReason = "The home at the specified address does not qualify for a free Powerpal installation";
        break;
    }

    if (canStillRegister) {
      this.showCanOnlyRegisterAlert(uneligibleReason);
      this.hideScheduleSelectInputs();
      return;
    }

    const eligibilityAlertElement = getElementById<HTMLElement>(ADDRESS_ELIGIBILITY_ALERT_ID);
    eligibilityAlertElement.innerHTML = `<p><strong>${uneligibleReason}</strong></p>`;
    showElementById(ADDRESS_ELIGIBILITY_ALERT_ID);

    this.setInputsDisabledState(true, { disableAddress: false });
  };

  private handleAddressGnafChanged = async (event: Event) => {
    const currentTarget = event.currentTarget as HTMLInputElement;

    const addressPostcodeInput = getElementById<HTMLInputElement>(ADDRESS_POSTCODE_INPUT_ID);
    const addressSuburbInput = getElementById<HTMLInputElement>(ADDRESS_SUBURB_INPUT_ID);
    const installationScheduleSelect = getElementById<HTMLSelectElement>(INSTALLATION_SCHEDULE_SELECT_INPUT_ID);
    const preferredTimeslotSelect = getElementById<HTMLSelectElement>(TIME_OF_DAY_SELECT_INPUT_ID);

    this.setInputsDisabledState(false);
    this.showScheduleSelectInputs();
    this.hideAllAlerts();

    const postcode = addressPostcodeInput.value;
    const suburb = addressSuburbInput.value;
    const gnafId = currentTarget.value;

    removeAllSelectOptions(installationScheduleSelect);
    removeAllSelectOptions(preferredTimeslotSelect);

    if (!postcode || !suburb) {
      return;
    }

    this.setIsLoading(true);

    const eligibility = await this.getAddressEligibility(postcode, suburb, gnafId);
    if (!eligibility || !eligibility.eligible) {
      this.setIsLoading(false);
      this.handleUneligibleAddress(eligibility?.reason ?? "");
      return;
    }

    this._bookingsAvailability = await this.getAvailableSchedules(postcode);

    this.setIsLoading(false);

    this._bookingWindowTimerAlert?.setBookingWindow(this._bookingsAvailability);
    if (!this._bookingsAvailability.isInsideBookingWindow) return;

    this.setScheduleSelectOptions(this._bookingsAvailability.schedules);
  };

  private handleInstallationScheduleChanged = (event: Event) => {
    const currentTarget = event.currentTarget as HTMLSelectElement;

    this.setPreferredTimeslots(this._bookingsAvailability.schedules);

    if (currentTarget.selectedIndex === 0) {
      hideElementById(NOTES_GROUP_ID);
      getElementById<HTMLInputElement>(NOTES_INPUT_ID).value = "";
    } else {
      showElementById(NOTES_GROUP_ID);
    }
  };
}
