export function disableLink(selector: string) {
  const link = document.querySelector(selector) as HTMLAnchorElement;
  link.dataset.originalHref = link.href;
  link.removeAttribute("href");
  link.setAttribute("disabled", "true");
}

export function enableLink(selector: string) {
  const link = document.querySelector(selector) as HTMLAnchorElement;
  link.removeAttribute("disabled");
  link.setAttribute("href", link.dataset.originalHref || "");
}

export function show(...els: HTMLElement[]) {
  els.forEach((el) => {
    if (el == null) return;
    el.classList.remove("is-hidden");
  });
}

export function hide(...els: HTMLElement[]) {
  els.forEach((el) => {
    if (el == null) return;
    el.classList.add("is-hidden");
  });
}

export function showAllHiddenUntilReady() {
  const els = document.querySelectorAll(".is-hidden-until-ready");
  els.forEach((el) => el.classList.remove("is-hidden-until-ready"));
}

export function createElementFromHTML(html: string): HTMLElement {
  var div = document.createElement("div");
  div.innerHTML = html.trim();
  return div.firstChild as HTMLElement;
}

export async function powerpalApiRequest(
  method: string,
  url: string,
  auth: string | null = null,
  payload: any | null = null
): Promise<any> {
  // Many of our APIs will return a user-facing error message in the response body when the status code is >=400.
  async function errorMessage(err: Error, res: Response | null, url: string) {
    if (res === null) return `${err.message}\n${url}`;

    try {
      let data = await res.json();
      if (data.error !== undefined) {
        return data.error;
      }
    } catch (jsonErr) {
      return `${err.message}\n${url}`;
    }
  }

  let res: Response | null = null; // Used by the catch block.

  const headers: { [k: string]: string } = {
    "Content-Type": "application/json",
  };

  if (auth !== null) {
    headers["Authorization"] = auth;
  }

  try {
    res = await fetch(url, {
      method: method,
      headers: headers,
      body: payload === null ? null : JSON.stringify(payload),
    });
    if (res.status >= 400) {
      throw new Error(`Response status was ${res.status}`);
    }

    // Some POSTs return an empty response. Only unmarshall if content-type is set.
    if (method === "GET" || res.headers.get("Content-Type")?.includes("application/json")) {
      return await res.json();
    }
  } catch (err: any) {
    let msg = await errorMessage(err, res, url);
    throw new Error(msg);
  }
}
