declare const window: Window & {
  powerpal: {
    tablesort: (table: HTMLTableElement | null) => void;
  };
};

class FacebookAdsTable {
  private readonly _table: HTMLTableElement;
  private readonly _createAdsButton: HTMLButtonElement;

  constructor(table: HTMLTableElement, createAdsButton: HTMLButtonElement) {
    this._table = table;
    this._createAdsButton = createAdsButton;
  }

  public init() {
    if (!this._table) return;

    this.wireUpShiftClickListeners();
    this.wireUpToggleCount();
    this.updateSelectedRowCountLabel();
    this.updateCreateAdsButtonDisabledState();

    for (let tr of Array.from(this._table.querySelectorAll("tbody tr")) as HTMLTableRowElement[]) {
      this.wireUpWholeRowToggle(tr);
      this.wireUpRowHighlight(tr);
    }
  }

  private updateSelectedRowCountLabel() {
    let selectedRowCountLabel = document.getElementById("selected-count-label");
    if (!selectedRowCountLabel) return;

    let checkedCheckboxes = Array.from(this._table.querySelectorAll(`input[type=checkbox]:checked`));

    selectedRowCountLabel.innerHTML = `${checkedCheckboxes.length} ads selected to create`;
  }

  private updateCreateAdsButtonDisabledState() {
    let checkedCheckboxes = Array.from(this._table.querySelectorAll(`input[type=checkbox]:checked`));

    this._createAdsButton.disabled = checkedCheckboxes.length === 0;
  }

  private wireUpToggleCount() {
    let container = this._table.querySelector(".toggle-count");
    if (!container) return;

    let button = container.querySelector("a");
    if (!button) return;

    let checkboxes = Array.from(this._table.querySelectorAll(`input[type=checkbox]`)) as HTMLInputElement[];

    function toggle() {
      const anyChecked = checkboxes.some((checkbox) => checkbox.checked);

      checkboxes.forEach((cb) => {
        cb.checked = !anyChecked;
        cb.dispatchEvent(new Event("change"));
      });
    }

    button.addEventListener("click", (ev) => {
      ev.preventDefault();
      toggle();
    });
  }

  private wireUpWholeRowToggle(tr: HTMLTableRowElement) {
    tr.addEventListener("click", (event: Event) => {
      if ((event.target as HTMLElement | null)?.nodeName != "TD") {
        return; // Don't intercept clicked on links
      }

      let checkbox: HTMLInputElement | null = tr.querySelector("input[type=checkbox]");
      if (!checkbox) return;

      checkbox.checked = !checkbox.checked;
      checkbox.dispatchEvent(new Event("change"));
    });
  }

  private wireUpRowHighlight(tr: HTMLTableRowElement) {
    let checkbox: HTMLInputElement | null = tr.querySelector("input[type=checkbox]");
    if (!checkbox) return;

    checkbox.addEventListener("change", (_event) => {
      tr.classList.toggle("is-selected", checkbox?.checked);
      this.updateSelectedRowCountLabel();
      this.updateCreateAdsButtonDisabledState();
    });

    // Trigger an initial change event to select checked rows on page load.
    checkbox.dispatchEvent(new Event("change"));
  }

  private wireUpShiftClickListeners() {
    const ref: FacebookAdsTable = this;
    const body: HTMLTableSectionElement | null = this._table.querySelector("tbody");
    if (!body) return;

    let isHoldingShiftKey: boolean = false;
    let currentRowClick: HTMLTableRowElement | null = null;

    document.addEventListener("keydown", function shiftKeyPressed(event) {
      isHoldingShiftKey = event.key === "Shift";
    });

    document.addEventListener("keyup", function shiftKeyReleased(_event) {
      isHoldingShiftKey = false;
    });

    document.addEventListener("selectstart", function select(event) {
      // We'll disable text selection when we're shift selecting rows to prevent annoying highlighting
      if (isHoldingShiftKey) event.preventDefault();
    });

    body.addEventListener("click", function click(event: Event) {
      if (isHoldingShiftKey && currentRowClick) {
        return ref.performShiftSelection(body, currentRowClick, event);
      }

      const clickedRow = ref.getClickedRowFromNode(event.target as HTMLElement);
      if (clickedRow) {
        currentRowClick = clickedRow;
        return;
      }

      currentRowClick = null;
    });
  }

  private performShiftSelection(body: HTMLTableSectionElement, currentRowClick: HTMLTableRowElement, event: Event) {
    const nextRowClicked = this.getClickedRowFromNode(event.target as HTMLElement);
    if (!nextRowClicked) return;

    const rows = Array.from(body.querySelectorAll("tr"));
    const initialClickIndex = rows.indexOf(currentRowClick);
    const nextClickIndex = rows.indexOf(nextRowClicked);
    const indexes =
      initialClickIndex < nextClickIndex ? [initialClickIndex, nextClickIndex] : [nextClickIndex, initialClickIndex];

    for (let i = indexes[0]; i <= indexes[1]; i++) {
      this.toggleCheckboxForRow(rows[i]);
    }
  }

  private toggleCheckboxForRow(row: HTMLTableRowElement) {
    if (!row) return;

    const checkbox: HTMLInputElement | null = row.querySelector("input[type=checkbox]");

    if (checkbox) {
      checkbox.checked = true;
      checkbox.dispatchEvent(new Event("change"));
    }
  }

  private getClickedRowFromNode(node: HTMLElement | null): HTMLTableRowElement | null {
    if (!node) return null;

    // This isn't supported on IE, but nobody should really be using IE since this is internal facing
    return node.closest("tr");
  }
}

export default function buildFacebookAdsTable(tableId: string, createAdsButtonId: string) {
  const table: HTMLTableElement = document.getElementById(tableId) as HTMLTableElement;
  const createAdsButton: HTMLButtonElement = document.getElementById(createAdsButtonId) as HTMLButtonElement;

  return new FacebookAdsTable(table, createAdsButton);
}
