export function createOption(value: string, text: string): HTMLOptionElement {
  const option = document.createElement("option");
  option.value = value;
  option.text = text;
  return option;
}

export function showElementById(elementId: string) {
  showElement(document.getElementById(elementId));
}

export function hideElementById(elementId: string) {
  hideElement(document.getElementById(elementId));
}

export function showElement(element: HTMLElement | null) {
  element?.classList.remove("hidden");
}

export function hideElement(element: HTMLElement | null) {
  element?.classList.add("hidden");
}

export function getElementById<TElementType extends HTMLElement | null>(id: string): TElementType {
  return document.getElementById(id) as TElementType;
}

export function removeAllSelectOptions(selectInput: HTMLSelectElement) {
  while (selectInput.options.length > 0) {
    selectInput.remove(0);
  }

  selectInput.value = "";
}
