export interface BookingWindow {
  isInsideBookingWindow: boolean;
  timeLeftSeconds: number;
  leniencyPeriodMinutes: number;
}

export interface Eligibility {
  eligible: boolean;
  reason: string;
}

export interface SuburbsForPostcode {
  error: string;
  suburbs: string[];
}

export interface Schedule {
  scheduleId: number;
  scheduleDate: string;
  availableSpots: number;
  routeType: string;
}

export interface AvailableSchedules {
  isInsideBookingWindow: boolean;
  timeLeftSeconds: number;
  leniencyPeriodMinutes: number;
  schedules: Schedule[];
}

export class BookingsService {
  private readonly _bookingWindowApiUrl: string;
  private readonly _eligibilityApiUrl: string;
  private readonly _suburbsApiUrl: string;
  private readonly _bookingsApiUrl: string;

  private readonly _csrfToken: string;

  constructor(
    csrfToken: string,
    bookingWindowApiUrl: string,
    eligibilityApiUrl: string,
    suburbsApiUrl: string,
    bookingsApiUrl: string
  ) {
    this._csrfToken = csrfToken;
    this._bookingWindowApiUrl = bookingWindowApiUrl;
    this._eligibilityApiUrl = eligibilityApiUrl;
    this._suburbsApiUrl = suburbsApiUrl;
    this._bookingsApiUrl = bookingsApiUrl;
  }

  public async getSuburbsForPostcode(postcode: string): Promise<SuburbsForPostcode> {
    return await this.getFromUrl(`${this._suburbsApiUrl}?postcode=${postcode}`);
  }

  public async getBookingWindow(): Promise<BookingWindow> {
    const bookingWindow = await this.getFromUrl(this._bookingWindowApiUrl);

    return {
      isInsideBookingWindow: bookingWindow.is_inside_booking_window,
      timeLeftSeconds: bookingWindow.time_until_booking_window_finishes_seconds,
      leniencyPeriodMinutes: bookingWindow.leniency_period_minutes,
    };
  }

  public async getEligibility(postcode: string, suburb: string, gnafId?: string): Promise<Eligibility> {
    let url = `${this._eligibilityApiUrl}?postcode=${postcode}&suburb=${suburb}`;
    if (gnafId) url += `&gnaf_id=${gnafId}`;

    return await this.getFromUrl(url);
  }

  public async getAvailablePostcodesWithSchedules(postcode?: string) {
    let url = this._bookingsApiUrl;
    if (postcode) url += `?postcode=${postcode}`;

    const response = await this.getFromUrl(url);

    const postcodes = [];
    for (const schedule of response.available_schedules) {
      postcodes.push(schedule.postcode);
    }

    return postcodes;
  }

  public async getAvailableSchedules(postcode?: string): Promise<AvailableSchedules> {
    let url = this._bookingsApiUrl;
    if (postcode) url += `?postcode=${postcode}`;

    const response = await this.getFromUrl(url);

    const schedules: Schedule[] = [];
    for (const schedule of (response.available_postcodes ?? []).flatMap((p: any) => p.schedules)) {
      schedules.push({
        scheduleId: schedule.schedule_id,
        scheduleDate: schedule.schedule_date,
        availableSpots: schedule.available_spots,
        routeType: schedule.route_type,
      });
    }

    return {
      isInsideBookingWindow: response.is_inside_booking_window,
      timeLeftSeconds: response.time_until_booking_window_finishes_seconds,
      leniencyPeriodMinutes: response.leniency_period_minutes,
      schedules: schedules,
    };
  }

  private async getFromUrl(url: string): Promise<any> {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ["X-CSRF-Token"]: this._csrfToken,
      },
    });

    return response.json();
  }
}
