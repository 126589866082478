import { powerpalApiRequest } from "./util";

export interface Distributor {
  code: string;
  name: string;
}

export interface Retailer {
  code: string;
  name: string;
}

export enum TariffType {
  singleRate = "single-rate",
  timeOfUse = "time-of-use",
}

export type TariffParams = TariffSingleRateParams | TariffTimeOfUseParams;

export interface TariffData {
  state: string;
  postcode: string;

  type: TariffType;
  variant: string;
  name: string;

  retailer_name: string;
  retailer_code: string;

  distributor_name: string;
  distributor_code: string;

  params: TariffParams;
}

export interface TariffSingleRateParams {
  supply_charge_cost_per_day: number;
  cost_per_kwh: number;
  discount_percent: number;
  discount_applies_to_supply_charge: boolean;
}

export interface TariffTimeOfUseParams {
  discount_percent: number;
  discount_applies_to_supply_charge: boolean;
  seasons: TimeOfUseSeason[];
}

export interface TimeOfUseSeason {
  name: string;
  from: string | null;
  to: string | null;
  supply_charge_cost_per_day: number;
  rates: TimeOfUseRate[];
}

export interface TimeOfUseRate {
  name: string;
  is_peak: boolean;
  cost_per_kwh: number;
  weekday: TimeWindow[];
  weekend: TimeWindow[];
}

export interface TimeWindow {
  start: string;
  end: string;
}

export interface TariffProviderLookup {
  distributors: Distributor[];
  retailers: Retailer[];
}

export interface TariffPlanLookup {
  plans: TariffData[];
}

export default class DevicesService {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  lookupTariffProviders = async (postcode: string): Promise<TariffProviderLookup> => {
    return await this.get(`/api/v1/tariffs/lookup?postcode=${postcode}`);
  };

  // TariffData will not be 100% populated. User-specific fields such as rates, retailer, distributor etc. will be missing from the plan template.
  lookupTariffPlans = async (distributorCode: string, retailerCode: string): Promise<TariffPlanLookup> => {
    return await this.get(`/api/v1/tariffs/plans?distributor_code=${distributorCode}&retailer_code=${retailerCode}`);
  };

  private get = async (path: string): Promise<any> => {
    return await powerpalApiRequest("GET", this.url + path);
  };
}
