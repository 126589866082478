export default class PasteOnlyTelephoneInput {
  private _telephoneInput: HTMLInputElement;
  private _clearButton: HTMLButtonElement;

  constructor(telephoneInput: HTMLInputElement, clearButton: HTMLButtonElement) {
    this._telephoneInput = telephoneInput;
    this._clearButton = clearButton;
  }

  public init = () => {
    this._telephoneInput.addEventListener("paste", this.handlePaste);
    this._telephoneInput.addEventListener("keydown", this.handleKeyDown);
    this._telephoneInput.addEventListener("keypress", this.handleKeyPress);

    this._clearButton.addEventListener("click", this.handleClearButtonClicked);
  };

  private handlePaste = (event: ClipboardEvent) => {
    event.stopPropagation();
    event.preventDefault();

    let pastedData = event.clipboardData?.getData("Text").replace(/ /g, "") ?? "";

    let telephoneRegex = /^[+]?[0-9]{10,}$/;

    if (!telephoneRegex.test(pastedData)) {
      this._telephoneInput.value = "";
      alert(
        `The pasted text '${pastedData}' is not a valid phone number.\n\nExpected a number that is at least 10 digits long, excluding spaces, that optionally starts with a '+' character for the country code.\ne.g. 0400123456, +61400123456 or 0340001234`
      );
      return;
    }

    this._telephoneInput.value = pastedData;
  };

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      event.stopPropagation();
      event.preventDefault();
      this._telephoneInput.value = "";
    }
  };

  private handleKeyPress = (event: KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  private handleClearButtonClicked = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    this._telephoneInput.value = "";
  };
}
