import loadImage from "blueimp-load-image";
import geolocation from "./geolocation";
import { show, hide } from "./util";

const getJpeg = (canvas) => {
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(resolve, "image/jpeg", 0.95);
    } catch (err) {
      reject(err);
    }
  });
};

const upload = async (url, file, filename, params = {}) => {
  let data = new FormData();
  data.append("file", file, filename);

  // Allow additional params to be sent (e.g. the installation token for replacement photos).
  for (let [key, value] of Object.entries(params)) {
    data.append(key, value);
  }

  let res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json", // We don't use the response but this is necessary to tell the server to return a 400 rather than redirecting on a bad token.
      ["X-CSRF-Token"]: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
    },
    credentials: "same-origin", // Chrome and Safari do this by default, but we had a case on a Samsung A20 (unknown browser) that did not send cookies.
    body: data,
  });

  if (res.status >= 400) {
    throw new Error(`Error ${res.status} uploading image to Powerpal server.\n\nPlease contact support@powerpal.net.`);
  }
};

const getCurrentPosition = () => {
  // Allow 2 minutes age on the location so we aren't requesting an update on each new page.
  const GEOLOCATION_OPTS = { enableHighAccuracy: true, timeout: 10 * 1000, maximumAge: 120 * 1000 };
  return geolocation.getCurrentPosition(GEOLOCATION_OPTS);
};

const geotag = async (jpeg, position) => {
  // Must include piexif on the page: <script src="https://cdn.jsdelivr.net/npm/piexifjs@1.0.6/piexif.min.js"></script>
  let exif = await readExif(jpeg);
  let { latitude, longitude } = position.coords;

  exif.GPS[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(latitude);
  exif.GPS[piexif.GPSIFD.GPSLatitudeRef] = latitude >= 0 ? "N" : "S";

  exif.GPS[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(longitude);
  exif.GPS[piexif.GPSIFD.GPSLongitudeRef] = longitude >= 0 ? "E" : "W";

  exif.Exif[piexif.ExifIFD.DateTimeOriginal] = moment().format("YYYY:MM:DD HH:mm:ss");

  window.exif = exif;

  return await writeExif(jpeg, exif);
};

const readExif = (blob) => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.addEventListener("load", (e) => {
        try {
          let result = piexif.load(e.target.result);
          resolve(result);
        } catch (err) {
          reject(err);
        }
      });
      reader.readAsDataURL(blob);
    } catch (err) {
      reject(err);
    }
  });
};

const writeExif = (blob, exifData) => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.addEventListener("load", async (e) => {
        try {
          let dataUrl = piexif.insert(piexif.dump(exifData), e.target.result);
          let blob = await dataUrlToBlob(dataUrl);
          resolve(blob);
        } catch (err) {
          reject(err);
        }
      });
      reader.readAsDataURL(blob);
    } catch (err) {
      reject(err);
    }
  });
};

const dataUrlToBlob = (dataUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await fetch(dataUrl);
      let blob = await res.blob();
      resolve(blob);
    } catch (err) {
      reject(err);
    }
  });
};

const camera = (opts) => {
  return new Promise((resolve, reject) => {
    try {
      // Android seems happy to generate whatever aspect ratio we ask for.
      // iOS does not give us 3:2 which means it doesn't fill the canvas and we get black borders.
      // We could resize the canvas when we find out what stream we have, but that produces UI jerk.
      // Safest to stick with a 4:3 ratio that should be supported by most devices.
      let defaults = {
        width: 900,
        height: 1200,
        facingMode: "environment",
        filename: "photo.jpg",
      };
      opts = { ...defaults, ...opts };

      // Start getting geolocation immediately to avoid waiting after capture.
      let currentPosition = getCurrentPosition();

      let container = document.getElementById(opts.id);
      let fileInput = container.querySelector("input[type=file]");
      fileInput.setAttribute("capture", opts.facingMode); // 'environment' or 'user'. Only works on iOS.

      let panels = {
        placeholder: container.querySelector(".placeholder"),
        sensor: container.querySelector(".sensor"),
        flash: container.querySelector(".flash"),
        preview: container.querySelector(".preview"),
      };

      let controls = {
        start: container.querySelector(".start"),
        spinner: container.querySelector(".spinner"),
      };

      function reset() {
        hide(panels.flash, panels.preview, controls.spinner);
        show(panels.placeholder, controls.start);
      }

      async function capture(file) {
        let data = await loadImage(file, {
          maxWidth: opts.width,
          canvas: true,
          orientation: true,
        });

        let canvas = data.image;

        // Order appears to matter here.
        // On iOS 15, setting `preview.src = canvas.toDataURL("image/jpeg")` BEFORE calling `canvas.toBlob` crashes the browser.
        // But only about 10% of the time. Race condition introduced in Safari 15 when two things interact with the canvas at the same time?
        let jpeg = await getJpeg(canvas);
        panels.preview.src = URL.createObjectURL(jpeg);

        let geotagged = await geotag(jpeg, await currentPosition);

        await upload(opts.uploadUrl, geotagged, opts.filename, opts.params);

        // We've re-enabled the start button to allow another photo to be taken and uploaded.
        // Resolve the promise so that our consumer knows that at least one valid photo has been captured.
        resolve();
      }

      fileInput.addEventListener("change", async (e) => {
        try {
          hide(panels.placeholder, controls.start);
          show(panels.preview, panels.flash, controls.spinner);

          await capture(e.target.files[0]);

          hide(panels.flash, controls.spinner);
          show(controls.start);
        } catch (err) {
          alert(`Error taking photo\n\n${err}`);
          reset();
        }
      });

      controls.start.addEventListener("click", () => fileInput.click());

      // Allow camera to be started initially by clicking anywhere on the placeholder image.
      // Require explicit button presses for shutter release and re-starts.
      panels.placeholder.addEventListener("click", () => controls.start.click());
    } catch (err) {
      alert(`Error creating camera\n\n${err}`);
    }
  });
};

const signature = (opts) => {
  return new Promise((resolve, reject) => {
    try {
      let defaults = {
        color: "black",
        filename: "signature.png",
      };
      opts = { ...defaults, ...opts };

      let container = document.getElementById(opts.id);

      let panels = {
        paper: container.querySelector(".paper"),
        title: container.querySelector(".title"),
        flash: container.querySelector(".flash"),
      };

      let controls = {
        clear: container.querySelector(".clear"),
        spinner: container.querySelector(".spinner"),
      };

      panels.paper.width = container.offsetWidth;
      panels.paper.height = container.offsetHeight;

      panels.title.addEventListener("click", (e) => {
        // Hide signature pad behind an overlay until first clicked on.
        // This allows the mobile user to scroll it into view without accidentally drawing a line.
        panels.title.remove();
        container.scrollIntoView();

        // Don't navigate to anchor if button was clicked rather than panel background.
        e.preventDefault();
      });

      let penUpTimer;

      let signaturePad = new SignaturePad(panels.paper, {
        penColor: opts.color,
        dotSize: 2,
        minDistance: 1,
        throtle: 5,
        onBegin: () => {
          clearTimeout(penUpTimer);
        },
        onEnd: () => {
          // Allow a little time for people to make multiple strokes before uploading the finished signature.
          penUpTimer = setTimeout(onFinished, 1000);
        },
      });

      async function onFinished() {
        try {
          show(panels.flash, controls.spinner);
          signaturePad.off();

          let png = await dataUrlToBlob(signaturePad.toDataURL());
          await upload(opts.uploadUrl, png, opts.filename);

          hide(controls.spinner);
          show(controls.clear);

          // We've enabled the clear button to allow another signature to be taken and uploaded.
          // Resolve the promise so that our consumer knows that at least one valid signature has been captured.
          resolve();
        } catch (err) {
          alert(`Error saving signature\n\n${err}`);
          reset();
        }
      }

      function reset() {
        signaturePad.clear();
        signaturePad.on();
        hide(panels.flash, controls.clear, controls.spinner);
      }

      controls.clear.addEventListener("click", reset);
    } catch (err) {
      alert(`Error creating signature pad\n\n${err}`);
    }
  });
};

export default { camera, signature };
