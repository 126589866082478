import geolocation from "./geolocation";

const showEarlierAppointments = (containerSelector) => {
  document
    .querySelector(containerSelector)
    .querySelectorAll(".appointment.historic")
    .forEach((el) => {
      el.classList.remove("is-hidden");
    });
};

const sendEstimatedTimeOfArrivalSMS = async (appointmentId) => {
  try {
    let button = document.getElementById(`send-sms-button-${appointmentId}`);
    let { url, address } = button.dataset;

    let setIcon = (icon) => (button.innerHTML = `<span class='icon'><i class='fas fa-${icon} fa-1x'></i></span>`);

    setIcon("spinner");
    let drivingTimeSeconds = await getDrivingTimeFromCurrentPosition(address);

    if (!confirm(`Send SMS with ETA in ${Math.round(drivingTimeSeconds / 60)} minutes?`)) {
      setIcon("sms");
      return;
    }

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ["X-CSRF-Token"]: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
      body: JSON.stringify({
        driving_time_seconds: drivingTimeSeconds,
      }),
    });

    button.classList.add("is-success");
    setIcon("check-circle");
  } catch (err) {
    alert(`Failed to send SMS with ETA\n\n${err}`);
  }
};

const callCustomer = async (appointmentId) => {
  let button = document.getElementById(`call-customer-button-${appointmentId}`);
  let { url, telephone } = button.dataset;

  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ["X-CSRF-Token"]: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
    },
  });

  let telephoneWithoutInternationalDialingPrefix = telephone.replace("+61", "0");
  let telephoneWithPrivateDialPrefix = encodeURIComponent(`#31#${telephoneWithoutInternationalDialingPrefix}`);
  window.location.href = `tel:${telephoneWithPrivateDialPrefix}`;
};

const getDrivingTimeFromCurrentPosition = async (address) => {
  let directionsService = new google.maps.DirectionsService();
  let position = await geolocation.getCurrentPosition();

  let directionsRequest = {
    origin: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
    destination: address,
    travelMode: "DRIVING",
  };

  let route = await new Promise((resolve, reject) => {
    directionsService.route(directionsRequest, (result, status) => {
      if (status == "OK") {
        resolve(result);
      }
      reject(new Error(`Error from Google directions API: ${status}`));
    });
  });

  return route.routes[0].legs[0].duration.value;
};

export default { showEarlierAppointments, sendEstimatedTimeOfArrivalSMS, callCustomer };
