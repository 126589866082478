module.exports = {
  getCurrentPosition: (opts = {}) => {
    return new Promise((resolve, reject) => {
      function onGeolocationError(err) {
        // PositionError does not have a useful message. Turn the code into something a human can use.
        switch (err.code) {
          case err.TIMEOUT:
            reject(new Error("Geolocation timed out."));
            break;
          case err.POSITION_UNAVAILABLE:
            reject(new Error("Geolocation is not available."));
            break;
          case err.PERMISSION_DENIED:
            reject(new Error("Geolocation permission has been denied."));
            break;
          default:
            reject(new Error(`Unknown geolocation error: ${err} (code ${err.code})`));
        }
      }
      navigator.geolocation.getCurrentPosition(resolve, onGeolocationError, opts);
    });
  },
};
